import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the type to include variables used in the flowchart
type DischargeDiagnosisType = {
  go1On?: boolean;
  contactService?: boolean;
  truckPowerWorking?: boolean;
  truckCommunication?: boolean;
  fuseWorking?: boolean;
  canWorking?: boolean;
  canSeparate?: boolean;
  configurationFileCorrect?: boolean;
  canSame?: boolean;
  truckOperational?: boolean;
  bmsWorking?: boolean;
  contactorWorking?: boolean;
  twelveVContactor?: boolean;
  twelveVWorking?: boolean;
  twelveVContinuity?: boolean;
  go1Working?: boolean;
  [key: string]: boolean | undefined; // Index signature for dynamic keys
};

// Define the context type
type DischargeDiagnosisContextType = {
  dischargeDiagnosis: DischargeDiagnosisType;
  setDischargeDiagnosis: (info: DischargeDiagnosisType) => void;
  currentNodeId: string;
  setCurrentNodeId: (nodeId: string) => void;
};

// Initialize the default state with relevant variables
const defaultDischargeDiagnosis: DischargeDiagnosisType = {
  go1On: false,
  contactService: false,
  truckPowerWorking: false,
  truckCommunication: false,
  fuseWorking: false,
  canWorking: false,
  canSeparate: false,
  configurationFileCorrect: false,
  canSame: false,
  truckOperational: false,
  bmsWorking: false,
  contactorWorking: false,
  twelveVContactor: false,
  twelveVWorking: false,
  twelveVContinuity: false,
  go1Working: false,
};

const DischargeDiagnosisContext = createContext<DischargeDiagnosisContextType>({
  dischargeDiagnosis: defaultDischargeDiagnosis,
  setDischargeDiagnosis: () => {},
  currentNodeId: 'module1',
  setCurrentNodeId: () => {},
});

export const DischargeDiagnosisProvider = ({ children }: { children: ReactNode }) => {
  const [dischargeDiagnosis, setDischargeDiagnosis] = useState<DischargeDiagnosisType>(defaultDischargeDiagnosis);
  const [currentNodeId, setCurrentNodeId] = useState<string>('module1');

  return (
    <DischargeDiagnosisContext.Provider value={{ dischargeDiagnosis, setDischargeDiagnosis, currentNodeId, setCurrentNodeId }}>
      {children}
    </DischargeDiagnosisContext.Provider>
  );
};

// Hook to use the DischargeDiagnosis context
export const useDischargeDiagnosis = () => {
  const context = useContext(DischargeDiagnosisContext);
  if (!context) {
    throw new Error('useDischargeDiagnosis must be used within a DischargeDiagnosisProvider');
  }
  return context;
};

export default DischargeDiagnosisContext;
