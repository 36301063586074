import React, { useState, useContext, useEffect } from 'react';
import { Text, Button, Flex, VStack, Spacer } from '@chakra-ui/react';
import BatteryInfoContext from '../../BatteryInfoContext'; // Ensure correct path

const KeyStatus: React.FC = () => {
  const { batteryInfo, setCanProceed, setBatteryInfo } = useContext(BatteryInfoContext);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    // Reset canProceed to false when the component loads
    setCanProceed(false);
  }, [setCanProceed]);

  const handleComplete = (step: number) => {
    setCurrentStep(step + 1); // Move to the next step
    if (step === 4) {
      setCanProceed(true);
    }
  };

  const handleKeyWorking = (isWorking: boolean) => {
    setBatteryInfo({ keyWorking: isWorking });
    handleComplete(3); // Progress to the next step after setting keyWorking
  };

  return (
    <VStack spacing={4} align="stretch">
      <Text fontSize="xl" mb={4}>Key Status</Text>

      {currentStep >= 1 && (
        <Flex>
          <Text>Navigate to the Monitor Tab in the BMS Tool</Text>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(1)}>Complete</Button>
        </Flex>
      )}

      {currentStep >= 2 && (
        <Flex>
          <Text>Click the Start button in the BMS Tool</Text>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(2)}>Complete</Button>
        </Flex>
      )}

      {currentStep >= 3 && (
        <Flex>
          <Text>Turn the Key Switch On on the battery</Text>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(3)}>Complete</Button>
        </Flex>
      )}

      {currentStep >= 4 && (
        <Flex>
          <Text>Does the checkbox marked "Key In" have a checkbox in it?</Text>
          <Spacer minW="0.5" />
          <Button bg={batteryInfo.keyWorking ? 'primary.light' : 'gray'} onClick={() => handleKeyWorking(true)}>Yes</Button>
          <Spacer minW="0.5" />
          <Button bg={!batteryInfo.keyWorking ? 'primary.light' : 'gray'} onClick={() => handleKeyWorking(false)}>No</Button>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(4)} isDisabled={batteryInfo.keyWorking === undefined}>Complete</Button>
        </Flex>
      )}
    </VStack>
  );
};

export default KeyStatus;
