import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, Button, Checkbox, Flex } from '@chakra-ui/react';
import BatteryInfoContext from '../../BatteryInfoContext'; // Adjust the import path as necessary

const BatteryStatus: React.FC = () => {
  const { batteryInfo, setBatteryInfo, setCanProceed } = useContext(BatteryInfoContext);

  const [truckOperational, setTruckOperational] = useState(false);
  const [chargerOperational, setChargerOperational] = useState(false);
  const [fuelGaugeOperational, setFuelGaugeOperational] = useState(false);

  useEffect(() => {
    // Set canProceed to false when the component loads
    setCanProceed(false);
  }, [setCanProceed]);

  const handleComplete = () => {
    setBatteryInfo({
      ...batteryInfo,
      truckOperational: !truckOperational,
      chargerOperational: !chargerOperational,
      fuelGaugeOperational: !fuelGaugeOperational,
      voltage: 4.0 // Assuming this is a static value for illustration
    });

    // Allow proceeding to the outer flowchart
    setCanProceed(true);
  };

  return (
    <Box>
      <Text fontSize="xl" mb={4}>Battery Status</Text>
      <Flex direction="column" gap="20px">
        <Checkbox isChecked={truckOperational} onChange={(e) => setTruckOperational(e.target.checked)}>
          Check if the truck will not operate with the battery on
        </Checkbox>
        <Checkbox isChecked={chargerOperational} onChange={(e) => setChargerOperational(e.target.checked)}>
          Check if the battery does not charge when plugged in
        </Checkbox>
        <Checkbox isChecked={fuelGaugeOperational} onChange={(e) => setFuelGaugeOperational(e.target.checked)}>
          Check if you believe the fuel gauge is not operating as expected
        </Checkbox>
        <Button color="white" bg="tertiary.dark" mt={4} onClick={handleComplete}>Complete</Button>
      </Flex>
    </Box>
  );
};

export default BatteryStatus;
