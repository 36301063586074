import flowchartJson from './flowchart.json';

// Define the structure of each node data
interface NodeData {
  id: string;
  module: string;
  nextNodes: string[];  // Each node's nextNodes includes the id of the next node and its condition as a string
}

// Define the mapping of ID to NodeData
interface FlowchartJson {
  [key: string]: NodeData;
}

const flowchartData: FlowchartJson = flowchartJson;

type Node = {
  id: string;
  module: string;
  nextNodes: [string, string][];  // Each entry is a tuple with the node id and the condition
};

type Nodes = {
  [key: string]: Node;
};

export class Flowchart {
  nodes: Nodes;

  constructor() {
    this.nodes = this.loadNodes();
  }

  loadNodes(): Nodes {
    const nodes: Nodes = {};
    Object.keys(flowchartData).forEach((key) => {
      const nodeData = flowchartData[key];
      nodes[key] = {
        id: nodeData.id,
        module: nodeData.module,
        nextNodes: this.formatNextNodes(nodeData.nextNodes || [])
      };
    });
    return nodes;
  }

  formatNextNodes(nextNodes: string[]): [string, string][] {
    const formatted: [string, string][] = [];
    for (let i = 0; i < nextNodes.length; i += 2) {
      formatted.push([nextNodes[i], nextNodes[i + 1]]);
    }
    return formatted;
  }

  getNextNode(currentNodeId: string, info: any): Node | null {
    const currentNode = this.nodes[currentNodeId];
    if (!currentNode) {
      console.error(`No current node found for ID: ${currentNodeId}`);
      return null;
    }

    // Check each nextNode candidate and evaluate its condition
    for (const [nextId, condition] of currentNode.nextNodes) {
      if (this.evaluateCondition(info, condition)) {
        return this.nodes[nextId];
      }
    }

    console.error(`No valid next node found from node ${currentNodeId}`);
    return null;
  }

  evaluateCondition(info: any, condition: string): boolean {
    try {
      // Directly access the property on the info object
      const keys = Object.keys(info).map(key => `let ${key} = info['${key}'];`).join(' ');
      const evalFunction = new Function('info', `${keys} return ${condition};`);
      return evalFunction(info);
    } catch (error) {
      console.error('Failed to evaluate condition:', condition, error);
      return false;
    }
  }
}

const flowchartInstance = new Flowchart();
export default flowchartInstance;
