import React from 'react';
import { BatteryInfoProvider } from './BatteryInfoContext';
import ModDisplay from './modDisplay';
import { Box, Text } from '@chakra-ui/react';

const Main: React.FC = () => {
  return (
    <BatteryInfoProvider>
      <Box
        position="relative"
        id="main_info"
        mx={["0%", "5%", "10%", "15%"]}
        bg="secondary.dark"
        pt="2%"
        pb="5%"
      >
        <ModDisplay />
      </Box>
    </BatteryInfoProvider>
  );
}

export default Main;
