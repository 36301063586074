import React, { useContext, useState, useEffect, Suspense } from 'react';
import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import BatteryInfoContext from './BatteryInfoContext';
import flowchart from '../flowchart/Flowchart';
import { loadModule } from '../utils/loadModule';

const ModDisplay: React.FC = () => {
  const {
    batteryInfo,
    setBatteryInfo,
    currentNodeId,
    setCurrentNodeId,
    visitedNodes,
    setVisitedNodes,
    canProceed,
  } = useContext(BatteryInfoContext);

  const [CurrentModule, setCurrentModule] = useState<React.FC<any> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (currentNodeId) {
      setLoading(true);
      setError("");
      const moduleToLoad = flowchart.nodes[currentNodeId]?.module;
      if (moduleToLoad) {
        try {
          const ModuleComponent = loadModule(moduleToLoad);
          setCurrentModule(() => ModuleComponent);
          setLoading(false);
        } catch (error) {
          setError(`Failed to load module: ${moduleToLoad}.`);
          setLoading(false);
        }
      } else {
        setError("No module found for the current node.");
        setLoading(false);
      }
    }
  }, [currentNodeId]);

  const handleNext = () => {
    if (canProceed) {
      const nextNode = flowchart.getNextNode(currentNodeId, batteryInfo);
      if (nextNode) {
        if (nextNode.id === 'end') {
          setCurrentNodeId('end');
          setVisitedNodes([]);
        } else {
          setVisitedNodes([...visitedNodes, currentNodeId!]);
          setCurrentNodeId(nextNode.id);
        }
      } else {
        console.error('No valid next node found');
      }
    }
  };

  const handleBack = () => {
    if (visitedNodes.length > 0) {
      const lastVisitedIndex = visitedNodes.length - 1;
      const lastVisitedNode = visitedNodes[lastVisitedIndex];
      setCurrentNodeId(lastVisitedNode);
      setVisitedNodes(visitedNodes.slice(0, lastVisitedIndex));
    }
  };

  if (loading) return <Box>Loading module...</Box>;
  if (error) return <Box>Error: {error}</Box>;

  if (currentNodeId === 'end') return <Box>End of the flowchart.</Box>;

  return (
    <VStack p={4} spacing={4} align="stretch" mx={12}>
      <Suspense fallback={<Box>Loading...</Box>}>
        {CurrentModule && <CurrentModule batteryInfo={batteryInfo} setBatteryInfo={setBatteryInfo} />}
      </Suspense>
      <Flex justify="center">
        <Button onClick={handleBack} colorScheme="gray" m={2} isDisabled={visitedNodes.length === 0}>Back</Button>
        <Button onClick={handleNext} bg="primary.light" m={2} isDisabled={!canProceed}>Next</Button>
      </Flex>
    </VStack>
  );
};

export default ModDisplay;
