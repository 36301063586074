// src/utils/moduleRegistry.ts
import BatteryIdentification from '../components/modules/battery_identification/BatteryIdentification';
import KeyDiagnosis from '../components/modules/key_diagnosis/KeyDiagnosis';
import KeyStatus from '../components/modules/key_status/KeyStatus';
import ServiceRequest from '../components/modules/service_request/ServiceRequest';
import UsbStatus from '../components/modules/usb_status/UsbStatus';
import BatteryStatus from '../components/modules/battery_status/BatteryStatus';
import DischargeDiagnosis from '../components/modules/discharge_diagnosis/DischargeDiagnosis';
import ChargeDiagnosis from '../components/modules/charge_diagnosis/ChargeDiagnosis';
import FuelGaugeDiagnosis from '../components/modules/fuel_gauge_diagnosis/FuelGaugeDiagnosis';
import End from '../components/modules/end/End';

const moduleRegistry: { [key: string]: React.FC<any> } = {
  BatteryIdentification,
  KeyDiagnosis,
  KeyStatus,
  ServiceRequest,
  UsbStatus,
  BatteryStatus,
  DischargeDiagnosis,
  ChargeDiagnosis,
  FuelGaugeDiagnosis,
  End
};

export default moduleRegistry;
