import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import logo from '../assets/images/logo.png'; // Import your logo image

const Navbar: React.FC = () => {
    return (
      <Box bg="secondary.default" id="navbar">
        <Flex alignItems="center" justifyContent="space-between" py={2} px={8}>
          <Box className="logo">
            <Image h="7vh" src={logo} alt="Company Logo" /> {/* Use imported logo */}
          </Box>
          <Flex className="title">
            <Text as="h3" fontSize="lg" justifyContent="space-between" color="tertiary.dark" fontWeight="bold">
                Green Cubes Service Diagnostic Tool
            </Text>
          </Flex>
        </Flex>
      </Box>
    );
  };
  
  export default Navbar;
