import React, { useContext, useEffect } from 'react';
import { Box, Text, Button, Flex, Spacer } from '@chakra-ui/react';
import FuelGaugeDiagnosisContext, { FuelGaugeDiagnosisProvider } from './FuelGaugeDIagnosisContext';
import BatteryInfoContext from '../../BatteryInfoContext'; // Adjust path as necessary
import flowchartInstance from './FuelGaugeDiagnosisFlowchart';

const FuelGaugeDiagnosisComponent: React.FC = () => {
  const { fuelGaugeDiagnosis, setFuelGaugeDiagnosis, currentNodeId, setCurrentNodeId } = useContext(FuelGaugeDiagnosisContext);
  const { batteryInfo, setBatteryInfo, setCanProceed, setCurrentNodeId: setBatteryNodeId } = useContext(BatteryInfoContext);
  const currentNode = flowchartInstance.nodes[currentNodeId];

  useEffect(() => {
    console.log('Current node updated:', currentNode);
    if (currentNodeId === 'end') {
      handleEndModule();
    }
  }, [currentNodeId]);

  const handleEndModule = () => {
    // Update the BatteryInfoContext with FuelGaugeDiagnosis values
    setBatteryInfo({
      ...batteryInfo,
      fuelGaugeOperational: fuelGaugeDiagnosis.fuelGaugerOperational,
      contactService: fuelGaugeDiagnosis.contactService,
    });

    // Allow proceeding to the outer flowchart
    setCanProceed(true);
  };

  const handleComplete = () => {
    if (currentNode.setVariables) {
      const updatedDiagnosis = { ...fuelGaugeDiagnosis };
      currentNode.setVariables.forEach(variable => {
        const [key, value] = variable.split('=');
        updatedDiagnosis[key] = value === 'true';
      });
      setFuelGaugeDiagnosis(updatedDiagnosis);
    }

    const nextNode = flowchartInstance.getNextNode(currentNodeId, fuelGaugeDiagnosis);
    if (nextNode) {
      setCurrentNodeId(nextNode.id);
    } else {
      console.error('No next node found');
    }
  };

  const handleYesNo = (value: boolean) => {
    if (currentNode.variableEntry) {
      const updatedDiagnosis = { ...fuelGaugeDiagnosis };
      const variable = currentNode.variableEntry;
      updatedDiagnosis[variable] = value;
      setFuelGaugeDiagnosis(updatedDiagnosis);
    }
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px">
      <Text fontSize="xl" mb={4}>{currentNode.prompt}</Text>
      {currentNodeId !== 'end' ? (
        currentNode.variableEntry ? (
          <Flex>
            <Button color="white" bg="primary.light" onClick={() => handleYesNo(true)}>Yes</Button>
            <Spacer minW="0.5rem" />
            <Button color="white" bg="gray" onClick={() => handleYesNo(false)}>No</Button>
            <Spacer minW="0.5rem" />
            <Button color="white" bg="tertiary.dark" onClick={handleComplete}>Complete</Button>
          </Flex>
        ) : (
          <Button color="white" bg="tertiary.dark" onClick={handleComplete}>Complete</Button>
        )
      ) : null}
    </Box>
  );
};

// Wrap FuelGaugeDiagnosisComponent in its context provider
const FuelGaugeDiagnosis: React.FC = (props) => {
  return (
    <FuelGaugeDiagnosisProvider>
      <FuelGaugeDiagnosisComponent {...props} />
    </FuelGaugeDiagnosisProvider>
  );
};

export default FuelGaugeDiagnosis;
