// theme.ts

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        margin: '0',
        padding: '0',
        fontFamily: '8xl Academy Engraved, sans-serif',
        backgroundColor: '#012107',
        color: 'white',
        height: '100%',
      },
    },
  },
  colors: {
    primary: {
      light: "#69eb41",
      default: "#46c019",
      dark: "#299514",
    },
    secondary: {
      light: "#46c019",
      default: "#299514",
      dark: "#14571c",
    },
    tertiary: {
      light: "#299514",
      default: "14571c",
      dark: "#012107",
    }
  }
});

export default theme;
