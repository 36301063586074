import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the type to include variables used in the flowchart
type ChargeDiagnosisType = {
  go2On?: boolean;
  contactService?: boolean;
  chargerPowerWorking?: boolean;
  chargerCommunication?: boolean;
  fuseWorking?: boolean;
  canWorking?: boolean;
  canSeparate?: boolean;
  configurationFileCorrect?: boolean;
  canSame?: boolean;
  chargerOperational?: boolean;
  bmsWorking?: boolean;
  contactorWorking?: boolean;
  twelveVContactor?: boolean;
  twelveVWorking?: boolean;
  twelveVContinuity?: boolean;
  go1Working?: boolean;
  go2Working?: boolean;
  chargeDetectWorking?: boolean;
  batVoltageWorking?: boolean;
  chargeDetectContinuity?: boolean;
  chargerWorking?: boolean;
  [key: string]: boolean | undefined; // Index signature for dynamic keys
};

// Define the context type
type ChargeDiagnosisContextType = {
  chargeDiagnosis: ChargeDiagnosisType;
  setChargeDiagnosis: (info: ChargeDiagnosisType) => void;
  currentNodeId: string;
  setCurrentNodeId: (nodeId: string) => void;
};

// Initialize the default state with relevant variables
const defaultChargeDiagnosis: ChargeDiagnosisType = {
  go2On: false,
  contactService: false,
  chargerPowerWorking: false,
  chargerCommunication: false,
  fuseWorking: false,
  canWorking: false,
  canSeparate: false,
  configurationFileCorrect: false,
  canSame: false,
  chargerOperational: false,
  bmsWorking: false,
  contactorWorking: false,
  twelveVContactor: false,
  twelveVWorking: false,
  twelveVContinuity: false,
  go1Working: false,
  go2Working: false,
  chargeDetectWorking: false,
  batVoltageWorking: false,
  chargeDetectContinuity: false,
  chargerWorking: false,
};

const ChargeDiagnosisContext = createContext<ChargeDiagnosisContextType>({
  chargeDiagnosis: defaultChargeDiagnosis,
  setChargeDiagnosis: () => {},
  currentNodeId: 'module1',
  setCurrentNodeId: () => {},
});

export const ChargeDiagnosisProvider = ({ children }: { children: ReactNode }) => {
  const [chargeDiagnosis, setChargeDiagnosis] = useState<ChargeDiagnosisType>(defaultChargeDiagnosis);
  const [currentNodeId, setCurrentNodeId] = useState<string>('module1');

  return (
    <ChargeDiagnosisContext.Provider value={{ chargeDiagnosis, setChargeDiagnosis, currentNodeId, setCurrentNodeId }}>
      {children}
    </ChargeDiagnosisContext.Provider>
  );
};

// Hook to use the ChargeDiagnosis context
export const useChargeDiagnosis = () => {
  const context = useContext(ChargeDiagnosisContext);
  if (!context) {
    throw new Error('useChargeDiagnosis must be used within a ChargeDiagnosisProvider');
  }
  return context;
};

export default ChargeDiagnosisContext;
