import React, { useState, useEffect, useContext } from 'react';
import { Text, Input, Checkbox, VStack, Flex } from '@chakra-ui/react';
import BatteryInfoContext from '../../BatteryInfoContext';

const BatteryIdentification: React.FC = () => {
  const { setCanProceed } = useContext(BatteryInfoContext);

  const [serialNumber, setSerialNumber] = useState('');
  const [model, setModel] = useState('');
  const [optionCode, setOptionCode] = useState('');
  const [isNewBattery, setIsNewBattery] = useState(false);

  const validateInput = (value: string, length: number): boolean => {
    return value.length === length && /^\d+$/.test(value);
  };

  useEffect(() => {
    const valid = validateInput(serialNumber, 8) && validateInput(model, 4) && validateInput(optionCode, 6);
    setCanProceed(valid);
  }, [serialNumber, model, optionCode, setCanProceed]);

  return (
    <VStack spacing={4} align="stretch">
      <Text fontSize="clamp(2rem, 5vw, 4rem)" fontWeight="bold" textAlign="center">Battery Identification</Text>
      <Flex justify="space-between" align="center">
        <Text w="150px">Serial Number:</Text>
        <Input placeholder="XXXXXXXX (Insert 8 digits)" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
      </Flex>
      <Flex justify="space-between" align="center">
        <Text w="150px">Model:</Text>
        <Input placeholder="FBP-XXXX (Insert 4 digits)" value={model} onChange={(e) => setModel(e.target.value)} />
      </Flex>
      <Flex justify="space-between" align="center">
        <Text w="150px">Option Code:</Text>
        <Input placeholder="XXXXXX (Insert 6 digits or 000000 if no option)" value={optionCode} onChange={(e) => setOptionCode(e.target.value)} />
      </Flex>
      <Checkbox isChecked={isNewBattery} onChange={(e) => setIsNewBattery(e.target.checked)}>New Battery</Checkbox>
    </VStack>
  );
};

export default BatteryIdentification;
