import React, { createContext, useState, ReactNode, useContext } from 'react';

type KeyDiagnosisType = {
  keySwitchWorking?: boolean;
  keyPositiveWorking?: boolean;
  fuseWorking?: boolean;
  keyPositiveChecked?: boolean;
  contactService?: boolean;
  keyWorking?: boolean;
  keyNegativeWorking?: boolean;
  keyNegativeChecked?: boolean;
  bmsWorking?: boolean;
  [key: string]: boolean | undefined; // Index signature for dynamic keys
};

type KeyDiagnosisContextType = {
  keyDiagnosis: KeyDiagnosisType;
  setKeyDiagnosis: (info: KeyDiagnosisType) => void;
  currentNodeId: string;
  setCurrentNodeId: (nodeId: string) => void;
};

const defaultKeyDiagnosis: KeyDiagnosisType = {
  keySwitchWorking: false,
  keyPositiveWorking: false,
  fuseWorking: false,
  keyPositiveChecked: false,
  contactService: false,
  keyWorking: false,
  keyNegativeWorking: false,
  keyNegativeChecked: false,
  bmsWorking: false,
};

const KeyDiagnosisContext = createContext<KeyDiagnosisContextType>({
  keyDiagnosis: defaultKeyDiagnosis,
  setKeyDiagnosis: () => {},
  currentNodeId: 'module1',
  setCurrentNodeId: () => {},
});

export const KeyDiagnosisProvider = ({ children }: { children: ReactNode }) => {
  const [keyDiagnosis, setKeyDiagnosis] = useState<KeyDiagnosisType>(defaultKeyDiagnosis);
  const [currentNodeId, setCurrentNodeId] = useState<string>('module1');

  return (
    <KeyDiagnosisContext.Provider value={{ keyDiagnosis, setKeyDiagnosis, currentNodeId, setCurrentNodeId }}>
      {children}
    </KeyDiagnosisContext.Provider>
  );
};

export const useKeyDiagnosis = () => {
  const context = useContext(KeyDiagnosisContext);
  if (!context) {
    throw new Error('useKeyDiagnosis must be used within a KeyDiagnosisProvider');
  }
  return context;
};

export default KeyDiagnosisContext;
