import React from 'react';
import moduleRegistry from './moduleRegistry';

export const loadModule = (moduleName: string): React.FC<any> => {
  console.log(`Attempting to load module: ${moduleName}`);

  const ModuleComponent = moduleRegistry[moduleName];

  if (ModuleComponent) {
    console.log(`Successfully loaded ${moduleName}`);
    return ModuleComponent;
  } else {
    const errorMessage = `Module ${moduleName} not found in registry.`;
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
};
