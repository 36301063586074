import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const End: React.FC = () => {
  return (
    <Box p={5} shadow="md" borderWidth="1px">
      <Text fontSize="xl" mb={4}>End of Diagnostic Session</Text>
      <Text>Click Next to start a new session.</Text>
    </Box>
  );
};

export default End;
