import React from 'react';
import { Box, Text, Button } from '@chakra-ui/react';

const ServiceRequest: React.FC = () => {
  const handleButtonClick = () => {
    window.open("https://greencubes.com/service-request/", "_blank"); // Opens in a new tab
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px">
      <Text fontSize="xl" mb={4}>Service Request</Text>
      <Button colorScheme="blue" onClick={handleButtonClick}>Go to GCT Website</Button>
    </Box>
  );
};

export default ServiceRequest;
