import React, { useState, useContext, useEffect } from 'react';
import { Text, Button, Flex, VStack, Spacer } from '@chakra-ui/react';
import BatteryInfoContext from '../../BatteryInfoContext';

const UsbStatus: React.FC = () => {
  const { batteryInfo, setBatteryInfo, setCanProceed } = useContext(BatteryInfoContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [bmsType, setBmsType] = useState('');

  useEffect(() => {
    // Reset canProceed to false when the component loads
    setCanProceed(false);
  }, [setCanProceed]);

  const handleComplete = (step: number) => {
    setCurrentStep(step + 1); // Move to the next step
    if (step === 5) {
      setCanProceed(true);
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <Text fontSize="xl" mb={4}>USB Status</Text>

      {currentStep >= 1 && (
        <Flex>
          <Text>What type of BMS does your battery use?</Text>
          <Spacer minW="0.5" />
          <Button bg={bmsType == 'Algo' ? 'primary.light' : 'gray'} onClick={() => setBmsType('Algo')}>Algo</Button>
          <Spacer minW="0.5" />
          <Button bg={bmsType == 'Industrial' ? 'primary.light' : 'gray'} onClick={() => setBmsType('Industrial')}>Industrial</Button>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(1)} isDisabled={!bmsType}>Complete</Button>
        </Flex>
      )}

      {currentStep >= 2 && (
        <Flex>
          <Text>Open the {bmsType} BMS application.</Text>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(2)}>Complete</Button>
        </Flex>
      )}

      {currentStep >= 3 && (
        <Flex>
          <Text>Connect the USB-A end of your cable to your laptop and the USB-B end of your cable to the battery.</Text>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(3)}>Complete</Button>
        </Flex>
      )}

      {currentStep >= 4 && (
        <Flex>
          <Text>Does the battery show up in the Product List?</Text>
          <Spacer minW="0.5" />
          <Button color="white" bg={batteryInfo.usbWorking ? 'primary.light' : 'gray'} onClick={() => setBatteryInfo({ ...batteryInfo, usbWorking: true })}>Yes</Button>
          <Spacer minW="0.5" />
          <Button color="white" bg={!batteryInfo.usbWorking && batteryInfo.usbWorking !== undefined ? 'primary.light' : 'gray'} onClick={() => setBatteryInfo({ ...batteryInfo, usbWorking: false })}>No</Button>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(4)} isDisabled={batteryInfo.usbWorking === undefined}>Complete</Button>
        </Flex>
      )}

      {currentStep >= 5 && (
        <Flex>
          <Text>
            {batteryInfo.usbWorking
              ? "Click the Connect button in the BMS application."
              : "Click Complete and then Next to get a link to the Green Cubes Service Request Form."}
          </Text>
          <Spacer minW="0.5" />
          <Button color="white" bg="tertiary.dark" onClick={() => handleComplete(5)}>Complete</Button>
        </Flex>
      )}
    </VStack>
  );
};

export default UsbStatus;
