import React, { useContext, useEffect } from 'react';
import { Box, Text, Button, Flex, Spacer } from '@chakra-ui/react';
import ChargeDiagnosisContext, { ChargeDiagnosisProvider } from './ChargeDIagnosisContext';
import BatteryInfoContext from '../../BatteryInfoContext'; // Adjust path as necessary
import flowchartInstance from './ChargeDiagnosisFlowchart';

const ChargeDiagnosisComponent: React.FC = () => {
  const { chargeDiagnosis, setChargeDiagnosis, currentNodeId, setCurrentNodeId } = useContext(ChargeDiagnosisContext);
  const { batteryInfo, setBatteryInfo, setCanProceed, setCurrentNodeId: setBatteryNodeId } = useContext(BatteryInfoContext);
  const currentNode = flowchartInstance.nodes[currentNodeId];

  useEffect(() => {
    console.log('Current node updated:', currentNode);
    if (currentNodeId === 'end') {
      handleEndModule();
    }
  }, [currentNodeId]);

  const handleEndModule = () => {
    // Update the BatteryInfoContext with ChargeDiagnosis values
    setBatteryInfo({
      ...batteryInfo,
      chargerOperational: chargeDiagnosis.chargerOperational,
      contactService: chargeDiagnosis.contactService,
    });

    // Allow proceeding to the outer flowchart
    setCanProceed(true);
  };

  const handleComplete = () => {
    if (currentNode.setVariables) {
      const updatedDiagnosis = { ...chargeDiagnosis };
      currentNode.setVariables.forEach(variable => {
        const [key, value] = variable.split('=');
        updatedDiagnosis[key] = value === 'true';
      });
      setChargeDiagnosis(updatedDiagnosis);
    }

    const nextNode = flowchartInstance.getNextNode(currentNodeId, chargeDiagnosis);
    if (nextNode) {
      setCurrentNodeId(nextNode.id);
    } else {
      console.error('No next node found');
    }
  };

  const handleYesNo = (value: boolean) => {
    if (currentNode.variableEntry) {
      const updatedDiagnosis = { ...chargeDiagnosis };
      const variable = currentNode.variableEntry;
      updatedDiagnosis[variable] = value;
      setChargeDiagnosis(updatedDiagnosis);
    }
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px">
      <Text fontSize="xl" mb={4}>{currentNode.prompt}</Text>
      {currentNodeId !== 'end' ? (
        currentNode.variableEntry ? (
          <Flex>
            <Button color="white" bg="primary.light" onClick={() => handleYesNo(true)}>Yes</Button>
            <Spacer minW="0.5rem" />
            <Button color="white" bg="gray" onClick={() => handleYesNo(false)}>No</Button>
            <Spacer minW="0.5rem" />
            <Button color="white" bg="tertiary.dark" onClick={handleComplete}>Complete</Button>
          </Flex>
        ) : (
          <Button color="white" bg="tertiary.dark" onClick={handleComplete}>Complete</Button>
        )
      ) : null}
    </Box>
  );
};

// Wrap ChargeDiagnosisComponent in its context provider
const ChargeDiagnosis: React.FC = (props) => {
  return (
    <ChargeDiagnosisProvider>
      <ChargeDiagnosisComponent {...props} />
    </ChargeDiagnosisProvider>
  );
};

export default ChargeDiagnosis;
