import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the type to include variables used in the flowchart
type FuelGaugeDiagnosisType = {
  contactService?: boolean;
  fuelGaugeReset?: boolean;
  fuelGaugeWorking?: boolean;
  fuelGaugeCalibration?: boolean;
  reloadDFI?: boolean;
  hardReset?: boolean;
  [key: string]: boolean | undefined; // Index signature for dynamic keys
};

// Define the context type
type FuelGaugeDiagnosisContextType = {
  fuelGaugeDiagnosis: FuelGaugeDiagnosisType;
  setFuelGaugeDiagnosis: (info: FuelGaugeDiagnosisType) => void;
  currentNodeId: string;
  setCurrentNodeId: (nodeId: string) => void;
};

// Initialize the default state with relevant variables
const defaultFuelGaugeDiagnosis: FuelGaugeDiagnosisType = {
  contactService: false,
  fuelGaugeReset: false,
  fuelGaugeWorking: false,
  fuelGaugeCalibration: false,
  reloadDFI: false,
  hardReset: false,
};

const FuelGaugeDiagnosisContext = createContext<FuelGaugeDiagnosisContextType>({
  fuelGaugeDiagnosis: defaultFuelGaugeDiagnosis,
  setFuelGaugeDiagnosis: () => {},
  currentNodeId: 'module1',
  setCurrentNodeId: () => {},
});

export const FuelGaugeDiagnosisProvider = ({ children }: { children: ReactNode }) => {
  const [fuelGaugeDiagnosis, setFuelGaugeDiagnosis] = useState<FuelGaugeDiagnosisType>(defaultFuelGaugeDiagnosis);
  const [currentNodeId, setCurrentNodeId] = useState<string>('module1');

  return (
    <FuelGaugeDiagnosisContext.Provider value={{ fuelGaugeDiagnosis, setFuelGaugeDiagnosis, currentNodeId, setCurrentNodeId }}>
      {children}
    </FuelGaugeDiagnosisContext.Provider>
  );
};

// Hook to use the FuelGaugeDiagnosis context
export const useFuelGaugeDiagnosis = () => {
  const context = useContext(FuelGaugeDiagnosisContext);
  if (!context) {
    throw new Error('useFuelGaugeDiagnosis must be used within a FuelGaugeDiagnosisProvider');
  }
  return context;
};

export default FuelGaugeDiagnosisContext;
