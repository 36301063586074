// App.tsx
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import Main from './components/Main';
import theme from './themes/theme'; // Import the theme configuration

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Main />
    </ChakraProvider>
  );
}

export default App;
