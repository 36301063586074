import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the BatteryInfo type with the new variables
type BatteryInfoType = {
  voltage?: number;
  current?: number;
  temperature?: number;
  usbWorking?: boolean;
  keyWorking?: boolean;
  contactService?: boolean; 
  truckOperational?: boolean; // New variable for truck Operational
  chargerOperational?: boolean; // New variable for charger Operational
  fuelGaugeOperational?: boolean; // New variable for fuel gauge Operational
};

// Define the context type, including new functions and state
type BatteryInfoContextType = {
  batteryInfo: BatteryInfoType;
  setBatteryInfo: (info: BatteryInfoType) => void;
  currentNodeId: string;
  setCurrentNodeId: (nodeId: string) => void;
  visitedNodes: string[];
  setVisitedNodes: (nodes: string[]) => void;
  canProceed: boolean;
  setCanProceed: (canProceed: boolean) => void;
  innerFlowchartComplete: boolean;
  setInnerFlowchartComplete: (complete: boolean) => void;
};

// Initialize the default state
const defaultBatteryInfo: BatteryInfoType = {
  voltage: undefined,
  current: undefined,
  temperature: undefined,
  usbWorking: undefined,
  keyWorking: undefined,
  contactService: undefined,
  truckOperational: undefined, // Initialize new variable
  chargerOperational: undefined, // Initialize new variable
  fuelGaugeOperational: undefined, // Initialize new variable
};

// Create the context with default values
const BatteryInfoContext = createContext<BatteryInfoContextType>({
  batteryInfo: defaultBatteryInfo,
  setBatteryInfo: () => {},
  currentNodeId: 'module1',
  setCurrentNodeId: () => {},
  visitedNodes: [],
  setVisitedNodes: () => {},
  canProceed: false,
  setCanProceed: () => {},
  innerFlowchartComplete: false,
  setInnerFlowchartComplete: () => {},
});

// Define the provider component
export const BatteryInfoProvider = ({ children }: { children: ReactNode }) => {
  const [batteryInfo, setBatteryInfo] = useState<BatteryInfoType>(defaultBatteryInfo);
  const [currentNodeId, setCurrentNodeId] = useState<string>('module1');
  const [visitedNodes, setVisitedNodes] = useState<string[]>([]);
  const [canProceed, setCanProceed] = useState<boolean>(false);
  const [innerFlowchartComplete, setInnerFlowchartComplete] = useState<boolean>(false);

  return (
    <BatteryInfoContext.Provider value={{
      batteryInfo,
      setBatteryInfo,
      currentNodeId,
      setCurrentNodeId,
      visitedNodes,
      setVisitedNodes,
      canProceed,
      setCanProceed,
      innerFlowchartComplete,
      setInnerFlowchartComplete
    }}>
      {children}
    </BatteryInfoContext.Provider>
  );
};

// Hook to use the BatteryInfo context
export const useBatteryInfo = () => {
  const context = useContext(BatteryInfoContext);
  if (!context) {
    throw new Error('useBatteryInfo must be used within a BatteryInfoProvider');
  }
  return context;
};

export default BatteryInfoContext;
